import { useQuery } from "react-query";
import { axiosMeduverse, removeEmptyField, transformQueryParams } from "../../../axiosConfigs";
import { LocalizedModel } from "../locales/models";
import { RewardItemModel } from "../../components/RewardItems";
import { MantineColor } from "@mantine/core";
import { SessionalRewardTypeEnum } from "./session-event/Constant";
import * as XLSX from 'xlsx';

export enum EventType {
    Finding = 1,
    Checkin = 2,
    Tournament = 5,
    Seasonal = 6,
    Resource = 7,
    Presence = 8
}

export class EventModel extends LocalizedModel {
    id?: string = undefined
    rewardItems?: any[];
    fromTime?: Date = new Date();
    toTime?: Date = new Date();
    dueRewardTime?: Date = undefined;
    thumbnail: any;
    type: any;
    title: string = '';
    content: string = ''
    description: string = ''
    enable: boolean = true
    objectFindingEvent?: any
    tournamentEvent?: TournamentEventModel
    name?: string;
}

export class ObjectRateModel {
    objectId?: string = undefined
    percentage: number = 0
    quantity: number = 0
    interval: number = 1
    liveTime: number = 1
}

export class ObjectRewardModel {
    id?: string = undefined
    description?: string = ''
    rewardItems?: any[] | undefined = [];
    objectIds: any;
    dueRewardTime: any;
}

export class PresenceRewardModel {
    id?: string = undefined
    rewardItems?: any[] | undefined = [];
    titleColor?: string;
    backgroundColor?: string;
}

export interface TournamentEventModel {
    eventId: string
    type: number
    rounds: any[]
    matches: any[]
    players: any[]
    viewers: any[]
    viewerMap: any
    allowGuest: boolean
    allowRegister: boolean
    allowRegisterTime?: Date
    registers: string[]
    maxViewer: number
}
export enum TournamentRoundStatus {
    Pending = 0,
    Started = 1,
    Paused = 2,
    Finished = 3
}
export enum RoundMode {
    Single = 0,
    Free = 1
}
export enum TournamentMatchMode {
    Scoring = 0,
    Knockout = 1
}
export interface TournamentRoundDto {
    autoOpenRoom: boolean;
    autoCreateMatches: boolean;
    questionBanks: string[];
    gradeUnits: any;
    estimatedCreateMatchTime: Date | null;
    estimatedOpenMatchTime: Date | null;
    endTime?: Date | null;
    startTime?: Date | null;
    id: string;
    eventId: string;
    index: number;
    status: TournamentRoundStatus;
    name: string;
    roomCount: number;
    maxPlayers: number;
    qualifyingPlayers: number;
    matchMode: TournamentMatchMode | string;
    questions: number;
    grades: number[] | string[];
    players: string[];
    roundRewards: RewardItemModel[];
    rankRewards: { [key: number]: RewardItemModel[]; };
    upcomingDescription: string;
    upcomingStartTime?: Date | null;
    upcomingEndTime?: Date | null;
    upcomingRepeat: string | null;
    pushMessage: string;
    pushStartTime?: Date | null;
    pushEndTime?: Date | null;
    pushRepeat: string | null;
    selectedRank: number | string;
    matches: number;
    upcomingURL: string;
    ongoingURL: string;
    waitingSeconds: number;
    autoStartRound: boolean;
    autoFinishRound: boolean;
    roundMode: RoundMode;
    activeTimeRanges: any[];
    activeTimeRangesText: string;
    maxMatchEachPlayer: number;
}

export interface TournamentMatchDto {
    roomId: string;
    estimatedOpenTime: string;
    matchMode: TournamentMatchMode;
    roundIndex: number;
    eventName: string;
    playerMap: any;
    viewerMap: any;
    name: string
    eventId: string
    roundId: string
    passKey: string
    status: TournamentMatchStatus | number
    players: string[]
    viewers: string[]
    points: any[]
    id: string
    createdAt: string
    createdBy: string
    isDeleted: boolean
}
export interface SessionalEventDto {
    eventId: string;
    type: EventType;
    eventReminderTimes: number[];
    eventTimes: number[];
    bankId: string;
    eventDuration: number;
    itemDropInterval: number;
    itemExistenceTime: number;
    maxQuestionsPerDay: number;
    numberItemDrop: number;
}
export class SessionRewardDto {
    itemId: string = '';
    rate?: number;
    rarity?: number;
    quantity?: number;
    minAmount?: number;
    maxAmount?: number;
    itemName?: string;
    quantityChange?: number;
    sessionalRewardType: SessionalRewardTypeEnum = SessionalRewardTypeEnum.None;
}

export enum TournamentMatchStatus {
    NotOpened = 0,
    Opened = 1,
    Playing = 5,
    Paused = 6,
    Finished = 10,
    Closed = 11
}
export const getMatchStatusColor = (status: TournamentMatchStatus): MantineColor => {
    if (status === TournamentMatchStatus.NotOpened) return "gray";
    if (status === TournamentMatchStatus.Opened) return "blue";
    if (status === TournamentMatchStatus.Playing) return "orange";
    if (status === TournamentMatchStatus.Finished) return "green";
    if (status === TournamentMatchStatus.Closed) return "red";
    return "gray";
}

export enum TournamentRankStatusEnum {
    PLAY = 0,
    DROP = 1,
    NO_JOIN = 2,
}

export function useEvents(filter: any = {}) {
    async function getData() {
        Object.keys(filter).forEach(x => {
            if (filter[x] === null) delete filter[x]
        })
        const params = new URLSearchParams(filter).toString()
        const response = await axiosMeduverse.get(`/manage/events?${params}`);
        //console.log(response);
        return response;
    }
    return useQuery(['events', filter], getData, { refetchOnWindowFocus: false })
}

export async function getEventDetail({ eventId, type }: any) {
    const params = new URLSearchParams({ eventId, type }).toString()
    const response = await axiosMeduverse.get(`/manage/events/detail?${params}`);
    return response;
}

export function useTournamentEvent(eventId?: string) {
    return useQuery(['tournament-event', eventId], () => getEventDetail({ eventId, type: EventType.Tournament }), { refetchOnWindowFocus: false, enabled: !!eventId })
}

export async function updateEvent(data: EventModel) {
    const response = await axiosMeduverse.post(`/manage/events`, data);
    return response;
}

export async function deleteEvents(data: Array<string>) {
    const params = new URLSearchParams({ ids: data.toString() }).toString()
    const response = await axiosMeduverse.delete(`/manage/events?${params}`);
    return response;
}

export function useEventObjects() {
    async function getData() {
        const response = await axiosMeduverse.get(`/manage/events/objects`);
        return response;
    }
    return useQuery(['event-items'], getData, { refetchOnWindowFocus: false })
}

export async function getObjectRates(eventId?: string) {
    const params = new URLSearchParams({ eventId } as any).toString()
    const response = await axiosMeduverse.get(`/manage/events/objectrates?${params}`);
    return response;
}

export function useObjectRates(eventId?: string) {
    return useQuery(['event-object-rates', eventId], () => getObjectRates(eventId), { refetchOnWindowFocus: false, enabled: eventId !== undefined })
}


export async function updateObjectRates(data: any) {
    console.log(data);
    const response = await axiosMeduverse.post(`/manage/events/objectrates`, data);
    return response;
}

export async function getObjectRewards(eventId: string) {
    const params = new URLSearchParams({ eventId }).toString()
    const response = await axiosMeduverse.get(`/manage/events/objectrewards?${params}`);
    return response;
}

export function useObjectRewards(eventId: string) {
    return useQuery(['object-rewards', eventId], () => getObjectRewards(eventId), { refetchOnWindowFocus: false, enabled: eventId !== undefined })
}
export async function updateObjectRewards(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/objectrewards`, data);
    return response;
}
export async function deleteObjectRewards(objectRewardId: any) {
    const response = await axiosMeduverse.delete(`/manage/events/objectrewards/${objectRewardId}`);
    return response;
}
export async function importPlayers(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/importplayers`, data);
    return response;
}
export async function clearPlayers(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/clearplayers`, data);
    return response;
}
export async function removePlayer(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/removeplayers`, data);
    return response;
}
export async function updateEventViewers(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/updateViewers`, data);
    return response;
}
export async function initRounds(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/initRounds`, data);
    return response;
}
export async function addRound(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/addround`, data);
    return response;
}
export async function saveRound(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/saveRound`, data);
    return response;
}
export async function startRound(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/startround`, data);
    return response;
}
export async function finishRound(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/finishround`, data);
    return response;
}
export async function resetRound(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/resetround`, data);
    return response;
}
export async function deleteRound(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/deleteround`, data);
    return response;
}
export async function saveRoundPlayers(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/saveRoundPlayers`, data);
    return response;
}
export async function addMatchPlayers(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/AddMatchPlayers`, data);
    return response;
}
export async function removeMatchPlayers(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/removeMatchPlayers`, data);
    return response;
}


export function useRoundPlayers(filter: any) {
    async function getData() {
        const params = new URLSearchParams(filter).toString()
        const response = await axiosMeduverse.get(`/manage/events/tournaments/getRoundPlayers?${params}`);
        //console.log(response);
        return response;
    }
    return useQuery(['rounds-rooms', filter], getData, { refetchOnWindowFocus: false, enabled: !!filter.roundId })
}

export function useTournamentMatches(filter: { eventId?: any, roundId?: any, pageIndex?: any }) {
    async function getData() {
        const params = new URLSearchParams(removeEmptyField(filter)).toString()
        const response = await axiosMeduverse.get(`/manage/events/tournaments/matches?${params}`);
        //console.log(response);
        return response;
    }
    return useQuery(['tournaments-rooms', filter], getData, { refetchOnWindowFocus: true, refetchInterval: 5000 })
}


export function useRounds(roundId?: string) {
    const { data, refetch } = useTournamentEvent(roundId)
    const event = (data?.data.event ?? {}) as EventModel
    const { tournamentEvent } = event
    const { rounds = [] } = tournamentEvent ?? {}
    return rounds
}

export async function openMatch(data: { matchId: string }) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/openmatch`, data);
    return response;
}

const masterURL = process.env.GATSBY_API_URL_MASTER
export async function startRoom({ roomId }: { roomId: string }) {
    const response = await axiosMeduverse.post(`/api/admin/StartRoom/${roomId}`, {}, { baseURL: masterURL });
    return response;
}
export async function initMatches(data: { roundId?: string }) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/InitRoundMatches`, data);
    return response;
}
export async function resetMatch(data: { matchId: string }) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/resetmatch`, data);
    return response;
}

export async function deleteMatch(data: { matchId: string }) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/deletematch`, data);
    return response;
}

export async function getLeaderboard(filter: { matchId: string }) {
    const params = new URLSearchParams(removeEmptyField(filter)).toString()

    const response = await axiosMeduverse.get(`/event/tournaments/leaderboard?${params}`,);
    return response;
}

export async function saveOpenMatchTime(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/tournaments/updateopentimematch`, data);
    return response;
}

export async function getRoundQuanlified(filter: { roundId: string }) {
    return await axiosMeduverse.get(`/manage/events/tournaments/roundqualified?${transformQueryParams(filter)}`,);
}

export async function saveAllowRegister(model: { eventId: string, allowRegister: boolean, allowRegisterTime?: Date }) {
    return await axiosMeduverse.post(`/manage/events/tournaments/allowregister?`, model);
}

export async function updateRoundParameters(model: any) {
    return await axiosMeduverse.post(`/manage/events/tournaments/updateRoundParameters`, model);
}

export function useSessionalRewards(eventId?: string) {
    return useQuery(['event-sessional-reward', eventId], () => getSessionalRewards(eventId), { refetchOnWindowFocus: false, enabled: eventId !== undefined })
}
export async function getSessionalRewards(eventId?: string) {
    const params = new URLSearchParams({ eventId } as any).toString()
    const response = await axiosMeduverse.get(`/manage/events/sessional/get-sessional-reward?${params}`);
    return response;
}
export async function updateSessionalRewards(data: any) {
    // console.log(data);
    if (data?.sessionalRewardDtos?.sessionalRewardType) data.sessionalRewardDtos.sessionalRewardType = parseInt(data?.sessionalRewardDtos?.sessionalRewardType.toString())
    const response = await axiosMeduverse.post(`/manage/events/sessional/save-sessional-reward`, data);
    return response;
}

export async function uploadQuestionsSessional(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/sessional/save-reward-rank-session`, data);
    return response;
}

export function usePopupNotifications() {
    async function getData() {
        const response = await axiosMeduverse.get(`/manage/mailbox/mailrecords/PopupNotification?pageIndex=1&pageSize=100`);
        return response;
    }
    return useQuery(['mailRecords'], getData, { refetchOnWindowFocus: false })
}

export async function pauseGame({ roomId }: { roomId: string }) {
    const response = await axiosMeduverse.post(`/api/admin/PauseGame/${roomId}`, {}, { baseURL: masterURL });
    return response;
}

export async function resumeGame({ roomId }: { roomId: string }) {
    const response = await axiosMeduverse.post(`/api/admin/ResumeGame/${roomId}`, {}, { baseURL: masterURL });
    return response;
}

export async function exchangeObjectToGold(model: { eventId: string, userCount: number }) {
    return await axiosMeduverse.post(`/event/object/change-to-gold`, model);
}

export async function processExchange(model: { eventId: string }) {
    return await axiosMeduverse.post(`/event/object/process-change-to-gold`, model);
}

export async function updatePresenceRewards(data: any) {
    const response = await axiosMeduverse.post(`/manage/events/presencerewards`, data);
    return response;
}

export function usePresenceRewards(filter: any) {
    const params = new URLSearchParams(filter).toString()
    async function getData() {
        const response = await axiosMeduverse.get(`/manage/events/presencerewards?${params}`);
        return response;
    }
    return useQuery(['presencerewards'], getData, { refetchOnWindowFocus: false })
}

export function useSeasonalReports(filter: any) {
    const { appType, languageId } = filter
    const params = new URLSearchParams({ appType, languageId }).toString()
    return axiosMeduverse.defaults.baseURL + `/report/seasonal-report?${params}`
}
export async function useSeasonalReport(filter: any) {
    const { EventId, Interval } = filter
    const params = new URLSearchParams({ EventId, Interval }).toString()
    const response = await axiosMeduverse.get(`/report/seasonal-report?${params}`);
    return response;
}
export async function useSeasonalReportCorrect(filter: any) {
    const { EventId, Interval } = filter
    const params = new URLSearchParams({ EventId, Interval }).toString()
    const response = await axiosMeduverse.get(`/report/seasonal-report-correct?${params}`);
    return response;
}
export async function useSeasonalExchangeReport(filter: any) {
    const { EventId } = filter
    const params = new URLSearchParams({ EventId }).toString()
    const response = await axiosMeduverse.get(`/report/seasonal-exchange-report?${params}`);
    return response;
}
export async function useSeasonalQuestion(filter: any) {
    const { EventId } = filter
    const params = new URLSearchParams({ EventId }).toString()
    const response = await axiosMeduverse.get(`/report/seasonal-question?${params}`);
    return response;
}
export async function useSeasonalQuestionDetail(filter: any) {
    const { EventId, Interval } = filter
    const params = new URLSearchParams({ EventId, Interval }).toString()
    const response = await axiosMeduverse.get(`/report/seasonal-question-detail?${params}`);
    return response;
}
export function downloadObjectAsCsv(exportObj: any, exportName: string) {
    const keys = Object.keys(exportObj);
    const csvData = [];
    for (let i = 0; i < exportObj[keys[0]].length; i++) {
        const row = [];
        for (const key of keys) {
            row.push(decodeURIComponent(exportObj[key][i]));
        }
        csvData.push(row.join(","));
    }
    const header = keys.join(",");
    const dataStr = `data:text/csv;charset=utf-8,\uFEFF,${encodeURIComponent(header + "\n" + csvData.join("\n"))}`;
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".csv");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}
export async function useObjectFindingUser() {
    const response = await axiosMeduverse.get(`/report/object-finding-user`);
    return response;
}
export async function useObjectFindingWord() {
    const response = await axiosMeduverse.get(`/report/object-finding-word`);
    return response;
}
export async function useObjectExchangeDailyReport() {
    const response = await axiosMeduverse.get(`/report/object-exchange-daily`);
    return response;
}