import { useQuery } from "react-query";
import { useCallback } from "react";
import { removeEmptyField, axiosMeduverse } from "../../../axiosConfigs";

export function useConfigs(filter: any = {}) {
    //console.log(filter)
    async function getData() {
        const params = new URLSearchParams(removeEmptyField(filter)).toString()
        const response = await axiosMeduverse.get(`/manage/appconfig?${params}`);
        //console.log(response);
        return response;
    }
    return useQuery(['configs', filter], getData, { refetchOnWindowFocus: false })
}

export function useSaveConfig() {
    return useCallback(async (data: any) => {
        if (data.gameTypes) data.gameTypes = data.gameTypes.map(Number)

        if (data.id) {
            const response = await axiosMeduverse.put(`/manage/appconfig`, data);
            return response;
        } else {
            const response = await axiosMeduverse.post(`/manage/appconfig`, data);
            return response;
        }
    }, [])
}

export async function getConfig(key: string) {
    const params = new URLSearchParams(removeEmptyField({ key })).toString()
    const response = await axiosMeduverse.get(`/manage/AppConfig/Get-Detail?${params}`);
    //console.log(response);
    return response;
}

export async function getAppConfigs(language: string = 'en') {
    const response = await axiosMeduverse.get(`/common/getconfigs?ExcludeItems=false`, { headers: { 'Client-Language': language } });
    return response;
}

export async function clearCache() {
    const response = await axiosMeduverse.post(`/manage/clear-cache`, {});
    return response;
}

export async function uploadConfigs() {
    const response = await axiosMeduverse.post(`/manage/uploadconfigs`, {});
    return response;
}

export async function getExceptionCount() {
    const response = await axiosMeduverse.get(`/manage/exceptioncount`);
    return response;
}

export function useExceptionCount() {
    return useQuery(['exceptions'], getExceptionCount, { refetchInterval: 10000 })
}