export enum Role {
    None = 0,
    Player = 1,
    Tester = 2,
    Bot = 3,
    Admin = 4,
    EventCreator = 5,
    Editor = 6,
    Reviewer = 61,
    BotOffline = 7,
    SalesMember = 8,
    SalesManager = 9,
    Teacher = 10,
    FPTManager = 11,
    Observer = 12,
}

export enum RealmRole {
    Admin = "Admin",
    SalesMember = "sales-member",
    SalesManager = "sales-manager",
    Teacher = "Teacher",
    FPTManager = "fpt-manager",
}
export enum NameStatusEnum {
    UNSCANNED = 0,
    SYSTEM_APPROVE = 1,
    NEED_REVIEW = 2,
    ADMIN_APPROVE = 3,
}

export enum JobStateStatusEnum {
    WAITING = 0,
    SCANNING = 1,
    DONE = 2,
    FAIL = 3,
}