import * as React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { RealmRole, Role } from "../modules/users/constants";
import { axiosMeduverse } from "../../axiosConfigs";
import { MantineSize } from "@mantine/core";
import { getConfig } from "../modules/configs/hooks";
import { notifications } from "@mantine/notifications";
import axios from "axios";

function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export class SigninProps {
    username: string = ''
    password: string = ''
    remember: boolean = false
    redirectUrl?: string | null | undefined = '/'
}

export class ForgotPasswordProps {
    username: string = ''
    otp: string = ''
    newPassword: string = ""
    repassword: string = ""
}

export class CreateUseProps {
    id?: string
    username: string = ''
    password: string = ''
    repassword: string = ''
    name: string = ''
    phone: string = ''
    email: string = ''
}

export type LayoutSizeProps = MantineSize | (string & {}) | number

export class AuthContextProps {
    userInfo: any | undefined
    signin: ((params: SigninProps) => void) | undefined
    createSaleUser: ((params: CreateUseProps) => void) | undefined
    signout: (() => void) | undefined;
    defaultLanguage: string = ''
    location?: Location
    token: string = ''
    step: any = 1
    setStepCount: ((step: number) => void) | undefined;
    forgotPassword: ((parmas: any) => void) | undefined
    forgotPasswordOtp: ((parmas: any) => void) | undefined
    forgotPasswordVeryfication: ((parmas: any) => void) | undefined
}

const AuthContext = createContext<AuthContextProps>(new AuthContextProps());

export function AppProvider({ children, pageProps }: any) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [userInfo, setUserInfo] = useState<any>();
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [token, setToken] = useState('');
    const { location } = pageProps ?? {}
    const [step, setStep] = useState(1);

    const signin = (params: SigninProps) => {
        const { username, password, remember, redirectUrl } = params
        axiosMeduverse.post(`/auth/login`, { username, password }, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            setCookie('user', x.data)
            if (redirectUrl) navigate(redirectUrl as any)
        })
    }
    const signout = () => {
        // console.log('deleteCookie')
        deleteCookie('user')
        // navigate('/')
    };

    const createSaleUser = (param: CreateUseProps) => {
        axiosMeduverse.post(`/auth/register/sale`, param, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            navigate('/signin' as any)
        })
    }

    useEffect(() => {
        // console.log('cookies', cookies)
        if (cookies.user && cookies.user.accessToken) {
            const decoded = jwtDecode(cookies.user.accessToken);
            const diff = dayjs().diff(dayjs.unix(decoded.exp as number))
            if (diff > 0) { // expired
                //navigate('/signin')
            } else {
                setUserInfo(cookies.user)
            }
        } else {
            setUserInfo(undefined)
            //navigate('/signin')
        }
    }, [cookies])

    useEffect(() => {
        if (!userInfo) return;

        getConfig('default_language').then(x => {
            if (x && x.data) {
                setDefaultLanguage(x.data.value)
            }
        })
    }, [userInfo])

    const forgotPassword = (params: any) => {
        axios.post(`/Account/users/forgot-password`, params, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            setStep(2)
            notifications.show({
                title: `Thành công`,
                message: `Mã OTP đã được gửi vào mail của bạn`,
            })
        })
    }

    const forgotPasswordOtp = (params: any) => {
        axios.post(`/Account/users/forgot-password-checkotp`, params, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            setStep(3)
            setToken(x.data.token);
        })
    }

    const forgotPasswordVeryfication = (params: any) => {
        axios.post(`/Account/users/forgot-password-verification`, params, {
            baseURL: process.env.GATSBY_API_URL,
        }).then((x) => {
            //console.log(x)
            if (!x) return;
            setStep(4)
            notifications.show({
                title: `Thành công`,
                message: `Đổi mật khẩu thành công`,
            })

            navigate('/signin' as any)
        })
    }

    const setStepCount = (step: number) => {
        setStep(step)
    }

    return (
        <>
            <AuthContext.Provider
                value={{
                    location, defaultLanguage, userInfo, signin, signout, createSaleUser, forgotPassword, forgotPasswordOtp,
                    forgotPasswordVeryfication, token, step, setStepCount
                }}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

export default AuthContext;

export function useAuthContext(): AuthContextProps {
    const context = useContext(AuthContext);
    return context;
}

export function useInRole(role: Role): boolean {
    const { userInfo } = useAuthContext();
    //console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;
        return userInfo?.role === role;
    }, [userInfo])

    //console.log(right)
    return right;
}

export function useCheckRoles(roles: Array<Role | RealmRole>): boolean {
    const { userInfo } = useAuthContext();

    // console.log('userInfo', userInfo)
    const right = React.useMemo(() => {
        if (!userInfo) return false;

        if (!roles) return false;

        if (roles.length === 0) return true

        const { accessToken, role: userInfoRole } = userInfo
        const decoded = jwtDecode(accessToken);
        // console.log(decoded)
        let checked = false;

        // @ts-ignore
        const yourRoles = [...decoded?.roles, ...decoded?.realm_access?.roles, userInfoRole]

        yourRoles?.forEach((role: Role) => {
            if (roles.includes(role) === true) checked = true
        })

        return checked;
    }, [userInfo])

    //console.log(right)
    return right;
}
