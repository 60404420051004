import { Alert, Text, Overlay, Stack, Title, Box } from "@mantine/core";
import React from "react";
import { useCheckRoles } from "../context/AppProvider";
import { Role } from "../modules/users/constants";

export function ReadOnlyOverlay() {
    const reviewer = useCheckRoles([Role.Reviewer])

    if (!reviewer) return null

    return <>
        <Alert mb={"md"} radius={'xl'} color="orange">
            <Box>
                <Title order={5} c={'orange'}>*** Read Only Mode ***</Title>
                <Text c={'dimmed'}>This page is in read-only mode. You can't make any changes.</Text>
            </Box>
        </Alert>
        <Overlay color="#000" backgroundOpacity={0.01} blur={0} />
    </>
}